$theme-colors: (
  "pap-main": rgb(36, 143, 68),
  "pap-secondary": #83a18d,
  "danger": #ed1c24,
  "green-500": rgba(16, 185, 129, 1),
  "green-1": #b5f7e690,
  "green-2": #89dfc890,
  "green-3": #056644,
  "green-4": #128061,
  "green-5": #266352,
);

@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", "Kumbh Sans", "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #707070;
  // height: auto;
  // overflow: hidden;
}

body:not(.modal-open) {
  padding-right: 0px !important;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0rem;
  & .title-container {
    position: relative;
    padding: 0.25rem 0rem;
    & h1 {
      font-size: 3rem;
      font-weight: 400;
      & span {
        color: #ef4136;
      }
    }
    &.valuable {
      & h1 {
        color: #fff;
      }
      &::after {
        background-color: #fff;
      }
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 70%;
      height: 2px;
      background-color: #ef4136;
    }
  }
}


.scroll-to-top-button {
  position: fixed;
  z-index: 99;
  bottom: 3rem;
  right: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  border-radius: 300px;
  background-color: #00000000;
  border: none;
  opacity: 0.5;
  transition: 0.2s;
  &:hover {
    opacity: 1;
    transition: 0.2s;
  }
  .scroll-to-top {
    width: 3.2rem;
    height: 3.2rem;
  }
}


.nav-bar {
  z-index: 100;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  left: 0;
  height: auto;
  height: min-content;
  width: 100%;
  padding: 25px 0;
  background-color: #f7f7f7;
  & .navbar-cont {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    & .logo {
      height: 4rem;
      margin-right: 3rem;
      object-fit: cover;
    }
  }
  .menus {
    padding: 0 !important;
    margin: 0 !important;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    list-style: none;
    & .contact {
      padding-bottom: 0.5rem;
      & .contact-wrapper {
        color: #ef4136;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 700;
      }
    }
    .menu {
      position: relative;
      transition: 0.3s ease;
      &::after {
        position: absolute;
        transition: 0.2s ease;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        width: 0%;
        height: 2px;
        background-color: #ef4136;
      }
      &.selected,
      &:hover {
        transition: 0.3s ease;
        & .item-menu {
          color: #ef4136;
        }
        &::after {
          width: 100%;
          transition: 0.2s ease;
        }
      }
      & .item-menu {
        color: #707070;
        display: block;
        text-decoration: none;
        background-color: transparent;
        border: none;
        padding: 0.5rem 0.25rem;
      }
    }
  }
}

.header-top {
  margin-bottom: 3rem;
  & .in-touch {
    background-image: url("/Assets/Images/header-thumbnail.jpg");
    width: 100%;
    height: 30rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0%;
    & .in-touch-wrapper {
      height: 100%;
      position: relative;
    }
  }
  .header-top-img {
    width: 100%;
    height: 30rem;
    object-fit: cover;
    object-position: 50% 0%;
  }

  .highlights {
    padding: 2.5rem 0;
    background-color: #ef4136;
    & .highlight {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      & .number {
        width: 30%;
        text-align: end;
        margin-right: 1rem;
        font-weight: 800;
        font-size: 3.5rem;
      }
      & .words {
        width: calc(70% - 1rem);
        & .top {
          margin-bottom: 0.25rem;
        }
        & span {
          padding: 0rem 0.5rem;
          background-color: #525252;
        }
      }
    }
  }
}

.in-touch-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  & .in-touch-text {
    font-size: 1.5rem;
    font-weight: 400;
    &.bigger {
      font-size: 1.8rem;
    }
    &.bold {
      font-weight: 500;
    }
  }
  h1 {
    font-weight: 700;
    font-size: 4rem !important;
    margin: 0.25rem 0rem !important;
  }
  & .in-touch-btn {
    margin-top: 1rem;
    display: block;
    text-align: center;
    padding: 1.5rem 0rem;
    width: 18rem;
    background-color: #ef4136;
    color: #fff;
  }
}

.service-home {
  margin-bottom: 3rem;
  & .service-link-wrapper {
    height: auto;
    & .service-link-container {
      position: relative;
      display: block;
      overflow: hidden;
      border-radius: 10px;
      & img {
        position: absolute;
        object-fit: contain;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  } 
}

.our-service {
  padding-bottom: 3rem;
  & .service-slider {
    margin: 0.75rem 0rem 1rem 0rem;
    & .container-slider {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 222px;
      & .slider-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 80;
        width: 30px;
        height: 30px;
        outline: 0px solid #525252;
        border: none;
        border-radius: 300px;
        color: #fff;
        background-color: #ef4136;
        display: flex;
        align-items: center;
        transition: 0.1s ease;
        justify-content: center;
        &:active {
          transition: 0.2s ease;
          outline: 3px solid #525252;
        }
        &.next {
          right: 0px;
        }
        &.prev {
          left: 0px;
        }
      }

      & .slider-display {
        position: relative;
        width: 1020px; // 1110px - 60px - 30px inside container width minus slider btn and its distance
        height: 100%;
        overflow: hidden;
        & .slider-track {
          width: auto;
          align-items: center;
          flex-wrap: nowrap;
          & .slider {
            position: absolute;
            padding: 1rem 0.5rem;
            top: 0;
            height: 100%;
            width: calc(1020px / 3);
            & .title-slide {
              display: flex;
              align-items: center;
              justify-content: center;
              & .icon-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3rem;
                height: 3.2rem;
                background-color: #ef4136;
                & .icon {
                  height: 2rem;
                  width: 2rem;
                  & path {
                    fill: #fff;
                  }
                }
              }
              & .title-wrapper {
                flex-grow: 1;
                height: 3.2rem;
                padding: 0rem 1rem;
                width: 85%;
                background-color: #525252;
                display: flex;
                align-items: center;
                transition: 0.3s ease;
                span {
                  color: #fff;
                  font-weight: 700;
                  font-size: 1rem;
                }
                &:hover {
                  transition: 0.3s ease;
                  background-color: #f7f7f7;
                  span {
                    color: #525252;
                  }
                }
              }
            }
            & .description {
              background-color: #f7f7f7;
              padding: 1rem;
              p {
                font-size: 0.7rem;
              }
            }
          }
        }
      }
    }
  }
}

.our-company {
  margin-bottom: 3rem;
  & .typograph {
    z-index: 50;
    top: 12.5%;
    transform: translateY(-50%);
    position: absolute;
    width: 100%;
    height: 5rem;
    object-fit: contain;
  }

  & .img-company-wrapper {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    & .our-comp-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
      object-fit: cover;
      object-position: 0% 100%;
    }
  }
  & .company-description {
    padding: 7rem 1rem 1rem 1rem;
    p {
      font-size: 1.2rem;
    }
  }

  & .our-company-cont {
    display: flex;
    & .img-company-container {
      width: calc(100% * 4 / 12);
    }
    & .company-description {
      width: calc(100% * 8 / 12);
    }
  }
}

.our-team {
  margin-bottom: 3rem;
  & .team-profile {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    & .card-wrapper {
      width: calc(100% / 4);
      padding: 1rem;
      & .profile-card {
        width: 100%;
        & .image-container {
          width: 100%;
          position: relative;
          padding-top: calc((1 / 1) * 100%);
          & .profile-photo {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          & .position {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0.25rem 1rem;
            background-color: #ef4136;
            color: #fff;
            font-weight: 700;
          }
        }
        & .name-container {
          background-color: #525252;
          height: 3.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            color: #fff;
            font-weight: 600;
            text-align: center;
          }
        }
      }
    }
  }
}

.valuable-client {
  width: 100%;
  max-height: 617px;
  height: calc((617 / 1920) * (100vw));
  background-image: url("/Assets/Images/valuable-client.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  & .valuable-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .client {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    & .client-icons {
      width: auto;
      height: calc((617 / 1920) * (100vw - 10px) * 0.25);
      max-height: 155.05px;
      &.bottom {
        height: calc((617 / 1920) * (100vw - 10px) * 0.15);
        max-height: 93.03px;
      }
      margin: 2rem 1rem 0 1rem;
      &:nth-child(7) {
        margin-right: 0rem !important;
      }
    }
  }
}

.footer {
  padding-top: 5rem;
  background-color: #f7f7f7;
  & .logo-footer {
    height: 4.5rem;
    width: auto;
    margin-bottom: 2rem;
  }
  & .address-container {
    h3 {
      font-size: 1.15rem;
      font-weight: 700;
    }
  }
  & .contact-container {
    & .contact-link {
      display: flex;
      align-items: center;
      & .contact-icon {
        margin-right: 1rem;
        height: 1rem;
        width: 1rem;
        path {
          fill: #ef4136;
        }
      }
      color: #707070 !important;
    }
  }
  & .copyright {
    border-top: 0.5px solid #707070;
    padding: 1rem 0 2rem 0;
    text-align: center;
    font-size: 0.9rem;
  }
}

.service-page {
  background-color: #F7F7F7;
}

.service-card-wrapper {
  overflow: visible;
  padding: 1rem;
  & .service-card {
    width: 100%;
    padding: 0.5rem 0.25rem;
    box-shadow: 0px 5px 10px 0px #0000000D;
    background: #FFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    &:hover {
      transform: scale(1.02);
      transition: 0.2s;
    }
    & .icon-card-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 3rem;
      & .icon-card {
        height: 2.5rem;
        width: 2.5rem;
        & path {
          fill: #ef4136;
        }
      }
    }
    & .service-title-card {
      font-size: 1.2rem;
      font-weight: 400;
      color: #707070;
    }
  }
}

.property-partners {
  padding: 1rem 0;
  & h1 {
    font-size: 2rem;
    font-weight: 700;
    color: #454240;
  }
  & img {
    width: auto;
    height: 70px;
    margin: 1rem 2rem;
  }
}

.marine-industry-detail {
  margin: 0 !important;
  padding: 2rem 0;
  border-bottom: 1px solid #E4E4E4;
  &:last-child {
    border-bottom: none !important;
  }
  height: max-content;
  & img {
    width: 100%;
    object-fit: contain;
    height: auto;
  }
  & h1 {
    color: #454240;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 1rem 0 !important;
  }
  & p {
    color: #000;
    font-size: 0.9rem;
  }
}

.vertical-centering {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  flex-grow: 1;
}

// for small device
@media only screen and (max-width: 576px) {
  .parent {
    overflow: hidden;
    position: relative;
    padding-top: calc(4rem - 1px);
  }

  .scroll-to-top-button {
    bottom: 2rem;
    right: 1.5rem;
    .scroll-to-top {
      width: 2.3rem;
      height: 2.3rem;
    }
  }

  .nav-bar {
    z-index: 100;
    // position: -webkit-sticky;
    position: fixed !important;
    top: -1px;
    left: 0;
    height: auto;
    height: min-content;
    padding: 0px;
    height: 4rem;
    background-color: #f7f7f7;
    & .navbar-cont {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      position: relative;
      & .logo {
        position: absolute;
        height: 2.5rem;
        width: auto;
        top: 50%;
        transform: translateY(-50%);
        left: 1.5rem;
        margin-right: 0px;
        object-fit: cover;
      }
    }
    & .menus {
      position: absolute;
      top: 0%;
      right: 0;
      transform: translateX(calc(100% + 2px));
      transition: 0.3s ease;
      &.open {
        transition: 0.3s ease;
        transform: translateX(0%);
      }
      height: 100vh;
      width: 60%;
      overflow: auto;
      padding: 0 !important;
      margin: 0 !important;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      list-style: none;
      background-color: #f7f7f7;
      z-index: 80;
      box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
      & .contact {
        margin-top: 2rem;
        padding-bottom: 0.5rem;
        & .contact-wrapper {
          color: #ef4136;
          text-decoration: none;
          font-size: 1.2rem;
          font-weight: 700;
        }
      }
      .menu {
        position: relative;
        transition: 0.3s ease;
        width: 100%;
        padding: 0.5rem;
        & .submenu {
          height: auto;
          list-style: none;
          display: list-item;
          flex: none;
          flex-direction: unset;
          position: relative;
          width: max-content;
          & .item-submenu-wrapper {
            margin: 0.5rem 0;
            & .item-submenu {
              // width: max-content;
              color: #707070;
              display: block;
            }
          }
        }
        &::after {
          position: absolute;
          transition: 0.2s ease;
          bottom: 50%;
          left: 0px;
          transform: translateY(50%);
          content: "";
          width: 2px;
          height: 0%;
          background-color: #ef4136;
        }
        &.selected,
        &:hover {
          transition: 0.3s ease;
          & .item-menu {
            color: #ef4136;
          }
          &::after {
            width: 2px;
            height: 100%;
            transition: 0.2s ease;
          }
        }
        & .item-menu {
          color: #707070;
          display: block;
          text-decoration: none;
          background-color: transparent;
          border: none;
          padding: 0.5rem 0.25rem;
        }
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0rem;
    & .title-container {
      position: relative;
      padding: 0.25rem 0rem;
      & h1 {
        font-size: 1.5rem;
        font-weight: 400;
        & span {
          color: #ef4136;
        }
      }
      &.valuable {
        & h1 {
          color: #fff;
        }
        &::after {
          background-color: #fff;
        }
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
        height: 2px;
        background-color: #ef4136;
      }
    }
  }

  .header-top {
    margin-bottom: 2rem !important;
    & .in-touch {
      width: 100%;
      height: 20rem;
    }

    .highlights {
      padding: 2.5rem 0;
      background-color: #ef4136;
      & .highlight {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 1rem;
        }
        & .number {
          width: 40%;
          text-align: end;
          margin-right: 0.5rem;
          font-weight: 800;
          font-size: 2.5rem;
        }
        & .words {
          width: calc(60% - 0.5rem);
          & .top {
            margin-bottom: 0rem;
          }
          & span {
            padding: 0rem 0.2rem;
            background-color: #525252;
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .in-touch-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    & .in-touch-text {
      font-weight: 400;
      font-size: 1rem;
      &.bigger {
        font-size: 1.3rem;
      }
      &.bold {
        font-weight: 500;
      }
    }
    h1 {
      font-weight: 700;
      font-size: 3rem !important;
      margin: 0.25rem 0rem !important;
    }
    & .in-touch-btn {
      margin-top: 1rem;
      display: block;
      padding: 1rem 2rem !important;
      width: 11.5rem;
      background-color: #ef4136;
      color: #fff;
    }
  }

  .our-service {
    padding-bottom: 1.5rem;
    & .service-slider {
      margin: 0.75rem 0rem 1rem 0rem;
      & .container-slider {
        & .slider-display {
          width: 330px; // 540px - 60px - 30px inside container width minus slider btn and its distance
          overflow: hidden;
          & .slider-track {
            & .slider {
              width: 330px;
            }
          }
        }
      }
    }
  }

  .service-home {
    margin-bottom: 3rem;
    & .service-link-wrapper {
      padding: 0 15px 0.5rem 15px;
      & .service-link-container {
        height: 3rem;
        & .service-link-text-container {
          position: absolute;
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          z-index: 3;
          background-color: rgba(239, 65, 54, 0.5);
          transition: 0.3s;
          & .service-link-text {
            font-weight: 700;
            color: white;
            font-size: 1.2rem;
            padding: 0 !important;
            margin: 0 !important;
          }
        }
        & img {
          transform: translate(-50%, -62.5%);
        }
      }
    }
  }

  .our-company {
    margin-bottom: 3rem;
    & .typograph {
      z-index: 50;
      top: 0px;
      left: 50%;
      transform: translate(-50%, 0);
      position: absolute;
      width: 80%;
      height: 3rem;
      object-fit: contain;
    }

    & .img-company-wrapper {
      position: relative;
      width: 100%;
      padding-top: 100%;
      overflow: hidden;
      & .our-comp-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        object-fit: cover;
        object-position: 0% 100%;
      }
    }
    & .company-description {
      padding: 1rem 1rem 1rem 1rem;
      p {
        font-size: 0.7rem;
        margin: 0px;
      }
    }

    & .our-company-cont {
      display: flex;
      flex-direction: column;
      position: relative;
      & .img-company-container {
        width: 100%;
      }
      & .company-description {
        position: relative;
        width: 100%;
        background-color: rgba(112, 112, 112, 0.6);
        p {
          color: #fff;
        }
      }
    }
  }

  .our-team {
    margin-bottom: 3rem;
    & .team-profile {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      & .card-wrapper {
        width: calc(100%);
      }
    }
  }

  .valuable-client {
    width: 100%;
    max-height: 300px;
    height: calc((300 / 540) * (100vw));
    // background-color: rgba(82, 82, 82, 0.5);
    background-image: url("/Assets/Images/valuable-client.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    & .valuable-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .client {
      height: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      & .client-icons {
        width: auto;
        height: calc((300 / 540) * (100vw - 10px) * 0.25);
        max-height: 2rem;
        &.bottom {
          height: calc((300 / 540) * (100vw - 10px) * 0.15);
          max-height: 1.5rem;
        }
        margin: 1rem 0.5rem 0 0.5rem;
        &:nth-child(7) {
          margin-right: 0rem !important;
        }
      }
    }
  }

  .service-card-wrapper {
    overflow: visible;
    padding: 1rem;
    & .service-card {
      width: 100%;
      padding: 0.25rem 0.125rem;
      box-shadow: 0px 5px 10px 0px #0000000D;
      background: #FFF;
      border-radius: 10px;
      display: flex;
      align-items: center;
      transition: 0.3s;
      &:hover {
        transform: scale(1.02);
        transition: 0.2s;
      }
      & .icon-card-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.5rem;
        height: 2.5rem;
        & .icon-card {
          height: 2rem;
          width: 2rem;
          & path {
            fill: #ef4136;
          }
        }
      }
      & .service-title-card {
        font-size: 0.9rem;
        font-weight: 400;
        color: #707070;
      }
    }
  }

  .property-partners {
    padding: 0.5rem 0;
    & h1 {
      font-size: 1.5rem;
      font-weight: 700;
      color: #454240;
    }
    & img {
      width: auto;
      height: 30px;
      margin: 0.5rem 1rem;
    }
  }

  .service-header {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 12rem;
    & img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
    & .service-header-title-container {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      z-index: 3;
      background-color: rgba(239, 65, 54, 0.5);
      & .service-header-title-flex-container {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
      & .service-header-title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;  
        & .service-header-title {
          padding: 0rem 2rem !important;
          margin: 0 !important;
          color: white;
          font-size: 3rem;
          font-weight: 700;
        }
      }
    }
  }

  .service-main-text {
    font-size: 1rem;
    font-weight: 400;
    color: #000;
    margin: 0 !important;
    padding: 1rem 0;
  }

  .mining-component-wrapper {
    height: max-content;
    & .map-image {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    & .mining-component-easy-access-wrapper {
      text-align: center;
    }
    & h1 {
      font-size: 1.2rem;
      font-weight: 700;
      color: #454240;
    }
    & p {
      color: #000;
      font-size: 0.8rem;
    }
    & .mining-component {
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }
}

// for medium device (tab or ipad)
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .parent {
    overflow: hidden;
    position: relative;
    padding-top: calc(4rem - 1px);
  }
  
  .scroll-to-top-button {
    bottom: 2rem;
    right: 2rem;
    .scroll-to-top {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  
  .nav-bar {
    z-index: 100;
    // position: -webkit-sticky;
    position: fixed !important;
    top: -1px;
    left: 0;
    height: auto;
    height: min-content;
    padding: 0px;
    height: 4rem;
    background-color: #f7f7f7;
    & .navbar-cont {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      position: relative;
      & .logo {
        position: absolute;
        height: 2.5rem;
        width: auto;
        top: 50%;
        transform: translateY(-50%);
        left: 1.5rem;
        margin-right: 0px;
        object-fit: cover;
      }
    }
    & .menus {
      position: absolute;
      top: 0%;
      right: calc(0px - (100vw - 540px)/2);
      transform: translateX(100%);
      transition: 0.3s ease;
      & .submenu {
        height: auto;
        list-style: none;
        display: list-item;
        flex: none;
        flex-direction: unset;
        position: relative;
        width: max-content;
        & .item-submenu-wrapper {
          margin: 0.5rem 0;
          & .item-submenu {
            // width: max-content;
            color: #707070;
            display: block;
          }
        }
      }
      &.open {
        transition: 0.3s ease;
        transform: translateX(0px);
      }
      height: calc(100vh + 1px);
      width: 60%;
      overflow: auto;
      padding: 0 !important;
      margin: 0 !important;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      list-style: none;
      background-color: #f7f7f7;
      z-index: 80;
      box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
      & .contact {
        margin-top: 2rem;
        padding-bottom: 0.5rem;
        & .contact-wrapper {
          color: #ef4136;
          text-decoration: none;
          font-size: 1.2rem;
          font-weight: 700;
        }
      }
      .menu {
        position: relative;
        transition: 0.3s ease;
        width: 100%;
        padding: 0.5rem;
        &::after {
          position: absolute;
          transition: 0.2s ease;
          bottom: 50%;
          left: 0px;
          transform: translateY(50%);
          content: "";
          width: 2px;
          height: 0%;
          background-color: #ef4136;
        }
        &.selected,
        &:hover {
          transition: 0.3s ease;
          & .item-menu {
            color: #ef4136;
          }
          &::after {
            width: 2px;
            height: 100%;
            transition: 0.2s ease;
          }
        }
        & .item-menu {
          color: #707070;
          display: block;
          text-decoration: none;
          background-color: transparent;
          border: none;
          padding: 0.5rem 0.25rem;
        }
      }
    }
  }

  
  .header-top {
    margin-bottom: 2rem !important;
    & .in-touch {
      width: 100%;
      height: 20rem;
    }
  }

  
  .in-touch-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    & .in-touch-text {
      font-weight: 400;
      font-size: 1rem;
      &.bigger {
        font-size: 1.3rem;
      }
      &.bold {
        font-weight: 500;
      }
    }
    h1 {
      font-weight: 700;
      font-size: 3rem !important;
      margin: 0.25rem 0rem !important;
    }
    & .in-touch-btn {
      margin-top: 1rem;
      display: block;
      padding: 1rem 2rem;
      width: 11.5rem;
      background-color: #ef4136;
      color: #fff;
    }
  }

  .our-service {
    padding-bottom: 2rem;
    & .service-slider {
      margin: 0.75rem 0rem 1rem 0rem;
      & .container-slider {
        & .slider-display {
          width: 450px; // 540px - 60px - 30px inside container width minus slider btn and its distance
          overflow: hidden;
          & .slider-track {
            & .slider {
              width: 450px;
            }
          }
        }
      }
    }
  }
  
  .our-company {
    margin-bottom: 3rem;
    & .typograph {
      z-index: 50;
      top: 0px;
      transform: translateY(0%);
      position: absolute;
      width: 100%;
      height: 5rem;
      object-fit: contain;
    }

    & .img-company-wrapper {
      position: relative;
      width: 100%;
      padding-top: 100%;
      overflow: hidden;
      & .our-comp-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        object-fit: cover;
        object-position: 0% 100%;
      }
    }
    & .company-description {
      padding: 1rem 1rem 1rem 1rem;
      p {
        font-size: 0.9rem;
      }
    }

    & .our-company-cont {
      display: flex;
      position: relative;
      & .img-company-container {
        width: 100%;
      }
      & .company-description {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: rgba(112, 112, 112, 0.6);
        p {
          color: #fff;
        }
      }
    }
  }

  .our-team {
    margin-bottom: 3rem;
    & .team-profile {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      & .card-wrapper {
        width: calc(100% / 2);
      }
    }
  }
  
  .valuable-client {
    width: 100%;
    max-height: 300px;
    height: calc((300 / 540) * (100vw));
    // background-color: rgba(82, 82, 82, 0.5);
    background-image: url("/Assets/Images/valuable-client.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    & .valuable-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .client {
      height: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      & .client-icons {
        width: auto;
        height: calc((300 / 540) * (100vw - 10px) * 0.25);
        max-height: 2.7rem;
        &.bottom {
          height: calc((300 / 540) * (100vw - 10px) * 0.15);
          max-height: 2.2rem;
        }
        margin: 1rem 0.5rem 0 0.5rem;
        &:nth-child(7) {
          margin-right: 0rem !important;
        }
      }
    }
  }

  .service-card-wrapper {
    overflow: visible;
    padding: 1rem;
    & .service-card {
      width: 100%;
      padding: 0.25rem 0.125rem;
      box-shadow: 0px 5px 10px 0px #0000000D;
      background: #FFF;
      border-radius: 10px;
      display: flex;
      align-items: center;
      transition: 0.3s;
      &:hover {
        transform: scale(1.02);
        transition: 0.2s;
      }
      & .icon-card-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 3rem;
        & .icon-card {
          height: 2.5rem;
          width: 2.5rem;
          & path {
            fill: #ef4136;
          }
        }
      }
      & .service-title-card {
        font-size: 1rem;
        font-weight: 400;
        color: #707070;
      }
    }
  }

  .service-header {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 18rem;
    & img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
    & .service-header-title-container {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      z-index: 3;
      background-color: rgba(239, 65, 54, 0.5);
      & .service-header-title-flex-container {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
      & .service-header-title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;  
        & .service-header-title {
          padding: 0rem 2rem !important;
          margin: 0 !important;
          color: white;
          font-size: 3rem;
          font-weight: 700;
        }
      }
    }
  }

  .property-partners {
    padding: 0.5rem 0;
    & h1 {
      font-size: 1.8rem;
      font-weight: 700;
      color: #454240;
    }
    & img {
      width: auto;
      height: 40px;
      margin: 0.5rem 1rem;
    }
  }

  .service-main-text {
    font-size: 1.2rem;
    font-weight: 400;
    color: #000;
    margin: 0 !important;
    padding: 1.5rem 0;
  }

  .mining-component-wrapper {
    height: max-content;
    & .map-image {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    & .mining-component-easy-access-wrapper {
      text-align: center;
    }
    & h1 {
      font-size: 1.2rem;
      font-weight: 700;
      color: #454240;
    }
    & p {
      color: #000;
      font-size: 0.9rem;
    }
    & .mining-component {
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }

  .service-home {
    margin-bottom: 3rem;
    & .service-link-wrapper {
      padding: 0 0 2rem 0;
      & .service-link-container {
        height: 4rem;
        & .service-link-text-container {
          position: absolute;
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          z-index: 3;
          background-color: rgba(239, 65, 54, 0.5);
          transition: 0.3s;
          & .service-link-text {
            font-weight: 700;
            color: white;
            font-size: 1.8rem;
            padding: 0 !important;
            margin: 0 !important;
          }
        }
        & img {
          transform: translate(-50%, -62.5%);
        }
      }
    }
  }
}

// for smaller notebook
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .nav-bar {
    z-index: 100;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    height: auto;
    height: min-content;
    padding: 25px 0;
    background-color: #f7f7f7;
    & .navbar-cont {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      & .logo {
        height: 3rem;
        margin-right: 1.5rem;
        object-fit: cover;
      }
    }
    .menus {
      padding: 0 !important;
      margin: 0 !important;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;
      list-style: none;
      & .contact {
        padding-bottom: 0.5rem;
        & .contact-wrapper {
          color: #ef4136;
          text-decoration: none;
          font-size: 1rem;
          font-weight: 700;
        }
      }
      .menu {
        position: relative;
        transition: 0.3s ease;
        & .submenu {
          list-style: none;
        }
        &::after {
          position: absolute;
          transition: 0.2s ease;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
          content: "";
          width: 0%;
          height: 2px;
          background-color: #ef4136;
        }
        &.selected,
        &:hover {
          transition: 0.3s ease;
          & .item-menu {
            color: #ef4136;
          }
          &::after {
            width: 100%;
            transition: 0.2s ease;
          }
        }
        & .item-menu {
          color: #707070;
          display: block;
          text-decoration: none;
          background-color: transparent;
          border: none;
          padding: 0.5rem 0.25rem;
          font-size: 0.9rem;
        }
      }
    }
  }

  .header-top {
    margin-bottom: 3rem;
    .header-top-img {
      width: 100%;
      height: 30rem;
      object-fit: cover;
      object-position: 50% 0%;
    }

    .highlights {
      padding: 2.5rem 0;
      background-color: #ef4136;
      & .highlight {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        & .number {
          width: 40%;
          text-align: end;
          margin-right: 0.5rem;
          font-weight: 800;
          font-size: 3rem;
        }
        & .words {
          width: calc(60% - 0.5rem);
          & .top {
            margin-bottom: 0.05rem;
          }
          & span {
            padding: 0rem 0.25rem;
            background-color: #525252;
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  .our-service {
    padding-bottom: 3rem;
    & .service-slider {
      margin: 0.75rem 0rem 1rem 0rem;
      & .container-slider {
        & .slider-display {
          width: 630px; // 970px - 60px - 30px inside container width minus slider btn and its distance
          overflow: hidden;
          & .slider-track {
            & .slider {
              width: calc(630px / 2);
            }
          }
        }
      }
    }
  }

  .our-company {
    margin-bottom: 3rem;
    & .typograph {
      z-index: 50;
      top: 0px;
      transform: translateY(0%);
      position: absolute;
      width: 100%;
      height: 5rem;
      object-fit: contain;
    }

    & .img-company-wrapper {
      position: relative;
      width: 100%;
      padding-top: 100%;
      overflow: hidden;
      & .our-comp-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        object-fit: cover;
        object-position: 0% 100%;
      }
    }
    & .company-description {
      padding: 1rem 1rem 1rem 1rem;
      p {
        font-size: 1.2rem;
      }
    }

    & .our-company-cont {
      display: flex;
      position: relative;
      & .img-company-container {
        width: 100%;
      }
      & .company-description {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: rgba(112, 112, 112, 0.6);
        p {
          color: #fff;
        }
      }
    }
  }

  .our-team {
    margin-bottom: 3rem;
    & .team-profile {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      & .card-wrapper {
        width: calc(100% / 2);
      }
    }
  }

  .valuable-client {
    width: 100%;
    max-height: 400px;
    height: calc((400 / 960) * (100vw));
    // background-color: rgba(82, 82, 82, 0.5);
    background-image: url("/Assets/Images/valuable-client.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    & .valuable-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .client {
      height: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      & .client-icons {
        width: auto;
        height: calc((617 / 1920) * (100vw - 10px) * 0.25);
        max-height: 155.05px;
        &.bottom {
          height: calc((617 / 1920) * (100vw - 10px) * 0.15);
          max-height: 93.03px;
        }
        margin: 2rem 1rem 0 1rem;
        &:nth-child(7) {
          margin-right: 0rem !important;
        }
      }
    }
  }

  .service-header {
    height: 30rem !important;
  }

  .service-home {
    margin-bottom: 3rem;
    & .service-link-wrapper {
      &:nth-child(odd) {
        padding: 0 1rem 2rem 0;
      }
      &:nth-child(even) {
        padding: 0 0 2rem 1rem;
      }
      & .service-link-container {
        height: 110.25px;
        & .service-link-text-container {
          position: absolute;
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          z-index: 3;
          background-color: rgba(239, 65, 54, 0.5);
          transition: 0.3s;
          & .service-link-text {
            font-weight: 700;
            color: white;
            font-size: 1.8rem;
            padding: 0 !important;
            margin: 0 !important;
          }
        } 
      }
    }
  }
}

// for pc
@media only screen and (min-width: 768px) {
  body {
    &::-webkit-scrollbar {
      display: block !important;
      background: #fff !important;
      width: 7px; /* width of the entire scrollbar */
      height: 7px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: #fff !important; /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #707070 !important; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 1.5px solid #fff;
    }
  }

  .menu {
    & .submenu {
      overflow: hidden;
      transition: 0.3s;
      height: auto;
      max-height: 0;
      list-style: none;
      display: flex;
      flex: 1 1 0px;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: calc(0% - 8px - 60%);
      width: max-content;
      max-width: 0;
      & .item-submenu-wrapper {
        flex: content;
        padding: 5px 8px;
        margin: 3px 0;
        border-radius: 5px;
        background-color: #F5F5F5;
        & .item-submenu {
          // width: max-content;
          color: #707070;
          display: block;
          white-space: nowrap;
        }
      }
    }
    &:hover .submenu, & .submenu.hover {
      transition: 0.3s;
      max-height: 160px;
      max-width: 217.54px;
    }
  }

  .service-header {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 38rem;
    & img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
    & .service-header-title-container {
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: 2;
      & .service-header-title-wrapper {
        display: flex;
        flex-direction: column;
        width: max-content;
        background-color: #ef4136;
        & .service-header-title {
          padding: 0rem 2rem !important;
          margin: 0 !important;
          color: white;
          font-size: 4rem;
          font-weight: 700;
        }
      }
    }
  }

  // each page of service
  .service-main-text {
    font-size: 1.6rem;
    font-weight: 400;
    color: #000;
    margin: 0 !important;
    padding: 2rem 0;
  }

  .mining-component-wrapper {
    height: max-content;
    & .map-image {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    & h1 {
      font-size: 2rem;
      font-weight: 700;
      color: #454240;
    }
    & p {
      color: #000;
    }
  }
}

.burger-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.5rem;
  z-index: 200;
}

.burger-button {
  height: 30px;
  width: 30px;
  position: relative;
  outline: none;
  border: none;
  background-color: transparent;
  & .child-1,
  .child-2,
  .child-3 {
    position: absolute;
    left: 0px;
    // transform: translateX(50%);
    width: 30px;
    height: 3px;
    background-color: #ef4136;
    border-radius: 300px;
  }
  & .child-1 {
    top: 20%;
    transform: translateY(-50%);
    transition: 0.5s ease;
    &.open {
      transform: rotate(45deg) translate(6.25px, 6.25px);
    }
  }
  & .child-2 {
    top: 50%;
    transform: translateY(-50%);
    transition: 0.5s ease;
    &.open {
      opacity: 0;
      transform: scale(0.2, 0.2);
    }
  }
  & .child-3 {
    top: 80%;
    transform: translateY(-50%);
    transition: 0.5s ease;
    &.open {
      transform: rotate(-45deg) translate(6.25px, -6.25px);
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .our-service {
    padding-bottom: 3rem;
    & .service-slider {
      margin: 0.75rem 0rem 1rem 0rem;
      & .container-slider {
        & .slider-display {
          width: 880px; // 970px - 60px - 30px inside container width minus slider btn and its distance
          overflow: hidden;
          & .slider-track {
            & .slider {
              width: calc(880px / 3);
            }
          }
        }
      }
    }
  }

  .our-company {
    margin-bottom: 3rem;
    & .typograph {
      z-index: 50;
      top: 12.5%;
      transform: translateY(-50%);
      position: absolute;
      width: 100%;
      height: 4rem;
      object-fit: contain;
    }
    & .company-description {
      padding: 6rem 1rem 1rem 1rem;
      p {
        font-size: 1rem;
      }
    }
  }

  .service-home {
    margin-bottom: 3rem;
    & .service-link-wrapper {
      &:nth-child(odd) {
        padding: 0 1rem 2rem 0;
      }
      &:nth-child(even) {
        padding: 0 0 2rem 1rem;
      }
      & .service-link-container {
        height: 148.7px;
        & .service-link-text-container {
          position: absolute;
          top: calc(100% - 10px);
          left: 10px;
          transform: translate(0, -100%);
          display: flex;
          flex: content;
          width: max-content;
          height: auto;
          min-width: 0%;
          min-height: 0%;
          align-items: center;
          justify-content: center;
          padding: 0.25rem 0.5rem;
          z-index: 3;
          background-color: #ef4136;
          transition: 0.3s;
          & .service-link-text {
            font-weight: 700;
            color: white;
            font-size: 1.8rem;
            padding: 0 !important;
            margin: 0 !important;
            transition: 0.3s;
          }
        }
        & img {
          transition: 0.3s;
        } 
        &:hover, &:active, &:visited, &:focus {
          & .service-link-text-container {
            background-color: rgba(239, 65, 54, 0.5);
            padding: 0.25rem 0.5rem;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            transform: translate(0, 0%);
            min-height: 100%;
            min-width: 100%;
            transition: 0.3s;
            & .service-link-text {
              font-weight: 700;
              color: white;
              font-size: 2.5rem;
              padding: 0 !important;
              margin: 0 !important;
              transition: 0.3s;
            }
          }
          & img {
            transform: translate(-50%, -50%) scale(1.1);
            transition: 0.3s;
          } 
        } 
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .service-home {
    margin-bottom: 3rem;
    & .service-link-wrapper {
      &:nth-child(odd) {
        padding: 0 1rem 2rem 0;
      }
      &:nth-child(even) {
        padding: 0 0 2rem 1rem;
      }
      & .service-link-container {
        height: 177.5px;
        & .service-link-text-container {
          position: absolute;
          top: calc(100% - 10px);
          left: 10px;
          transform: translate(0, -100%);
          display: flex;
          flex: content;
          width: max-content;
          height: auto;
          min-width: 0%;
          min-height: 0%;
          align-items: center;
          justify-content: center;
          padding: 0.25rem 0.5rem;
          z-index: 3;
          background-color: #ef4136;
          transition: 0.3s;
          & .service-link-text {
            font-weight: 700;
            color: white;
            font-size: 2rem;
            padding: 0 !important;
            margin: 0 !important;
            transition: 0.3s;
          }
        }
        & img {
          transition: 0.3s;
        } 
        &:hover, &:active, &:visited, &:focus {
          & .service-link-text-container {
            background-color: rgba(239, 65, 54, 0.5);
            padding: 0.25rem 0.5rem;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            transform: translate(0, 0%);
            min-height: 100%;
            min-width: 100%;
            transition: 0.3s;
            & .service-link-text {
              font-weight: 700;
              color: white;
              font-size: 3rem;
              padding: 0 !important;
              margin: 0 !important;
              transition: 0.3s;
            }
          }
          & img {
            transform: translate(-50%, -50%) scale(1.1);
            transition: 0.3s;
          } 
        } 
      }
    }
  }
}

.container-slider {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container-slider {
    width: 540px;
  }
}
@media (min-width: 768px) {
  .container-slider {
    width: 720px;
  }
}
@media (min-width: 992px) {
  .container-slider {
    width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-slider {
    width: 1140px;
  }
}
